
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import { ISelectItem } from "@/types";
import {
  IPriceTagSupplier,
  IPriceTagSupplierCreateRequest,
  IPriceTagSupplierRequest,
  IPriceTagSupplierUpdateRequest
} from "@/types/price_tag_supplier";
import { PriceTagSupplierRequest } from "@/models/price_tag_supplier";
import { ISupplier, ISupplierListRequest } from "@/types/supplier";
import { IPriceTag } from "@/types/price_tag";

@Component({})
export default class extends Vue {
  //price_tag_supplier
  @Action("price_tag_supplier/adminGet")
  public getPriceTagSupplier!: (price_tag_supplier_id: number) => void;

  @Action("price_tag_supplier/adminCreate")
  public create!: (params: IPriceTagSupplierCreateRequest) => boolean;

  @Action("price_tag_supplier/adminUpdate")
  public update!: (data: {
    price_tag_supplier_id: number;
    params: IPriceTagSupplierUpdateRequest;
  }) => boolean;

  @Action("price_tag_supplier/adminDelete")
  public deletePriceTagSupplier!: (price_tag_supplier_id: number) => boolean;

  @Action("price_tag_supplier/adminIsUniqueSupplierPN")
  public isUniqueSupplierPN!: (params: IPriceTagSupplierRequest) => boolean;

  @Getter("price_tag_supplier/single")
  public price_tag_supplier!: IPriceTagSupplier;

  @Mutation("price_tag_supplier/clear")
  public clearPriceTagSupplier!: () => void;

  //price_tag
  @Action("price_tag/adminGet")
  public getPriceTag!: (price_tag_id: number) => void;

  @Getter("price_tag/single")
  public price_tag!: IPriceTag;

  @Mutation("price_tag/clear")
  public clearPriceTag!: () => void;

  //supplier
  @Action("supplier/adminGetList")
  public getSuppliers!: (params: ISupplierListRequest) => boolean;

  @Getter("supplier/selectItem")
  public supplierList!: ISelectItem[];

  @Getter("supplier/find")
  public findSupplier!: (id: number) => ISupplier;

  @Mutation("supplier/clear")
  public clearSupplier!: () => void;

  //パラメータ定義
  public params: IPriceTagSupplierRequest = new PriceTagSupplierRequest();

  //変数定義
  public price_tag_supplier_id = 0;
  public price_tag_id = 0;
  public price_tag_name = "";
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public lazy = false;
  public supplier_pn_errors: string[] = [];

  //ルール設定
  public rules = {
    supplier_pn: [(v: string) => !!v || "サプライヤ品番は必須です"],
    supplier_id: [(v: number) => !!v || "サプライヤは必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.clearPriceTag();
    this.clearSupplier();
    this.clearPriceTagSupplier();

    this.price_tag_id = Number(this.$route.params.price_tag_id) || 0;
    this.price_tag_supplier_id =
      Number(this.$route.params.price_tag_supplier_id) || 0;

    await this.getSuppliers({ per_page: 0 });

    //新規時
    if (this.price_tag_id) {
      await this.getPriceTag(this.price_tag_id);
      this.setCreateDefault();
    }

    // 編集時
    if (this.price_tag_supplier_id) {
      await this.getPriceTagSupplier(this.price_tag_supplier_id);
      this.setUpdateDefault();
    }

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  //新規時デフォルト値をセットする;
  private async setCreateDefault() {
    this.params.price_tag_id = this.price_tag.id;
    this.price_tag_name = this.price_tag.name;
  }

  //--------
  //更新時デフォルト値をセットする;
  private async setUpdateDefault() {
    this.params.createFromPriceTagSupplier(this.price_tag_supplier);
    this.price_tag_name = this.price_tag_supplier.price_tag_name;
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;
    if (this.price_tag_supplier_id) {
      result = await this.update({
        price_tag_supplier_id: this.price_tag_supplier_id,
        params: { ...this.params, id: this.price_tag_supplier.id }
      });
    } else {
      result = await this.create(this.params);
    }
    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deletePriceTagSupplier(this.price_tag_supplier_id)) {
      this.$router.go(-1);
    }
  }

  // サプライヤ品番の二重登録チェック
  public async supplier_pn_validate() {
    // 登録時
    if (
      !(await this.isUniqueSupplierPN(this.params)) &&
      !this.price_tag_supplier.supplier_pn
    ) {
      return (this.supplier_pn_errors = ["既に使用されています"]);
    }

    // 編集時
    if (
      !(await this.isUniqueSupplierPN(this.params)) &&
      this.price_tag_supplier.supplier_pn != this.params.supplier_pn
    ) {
      return (this.supplier_pn_errors = ["既に使用されています"]);
    }

    return (this.supplier_pn_errors = []);
  }
}
